import * as React from 'react';
import { Link } from 'gatsby';
import { CssBaseline } from '@mui/material';
import H1 from '../components/typo/h1';
import { StyledCode, StyledMain, StyledParagraph } from '../style/pages/404.styles';

function NotFoundPage() {
  return (
    <StyledMain>
      <CssBaseline />
      <title>Not found</title>
      <H1>Page not found</H1>
      <StyledParagraph>
        Sorry
        {' '}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>
        {' '}
        we couldn’t find what you were looking for.
        <br />
        {process.env.NODE_ENV === 'development' ? (
          <>
            <br />
            Try creating a page in
            {' '}
            <StyledCode>src/pages/</StyledCode>
            .
            <br />
          </>
        ) : null}
        <br />
        <Link to="/">Go home</Link>
        .
      </StyledParagraph>
    </StyledMain>
  );
}

export default NotFoundPage;
