import React from 'react';
import styled from '@emotion/styled';

const StyledH1 = styled.h1`
  font-size: 2.2em;
  text-align: center;
  z-index: 1;
`;

function H1({ children } : { children: string }) {
  return (
    <StyledH1>{children}</StyledH1>
  );
}

export default H1;
