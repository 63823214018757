import styled from '@emotion/styled';

const StyledMain = styled.main`
  color: #232129;
  padding: 96px;
  font-family: scandia-line-web, sans-serif, serif;
`;

const StyledParagraph = styled.p`
  margin-bottom: 48;
`;

const StyledCode = styled.code`
  color: #8A6534;
  padding: 4;
  background-color: #FFF4DB;
  font-size: 1.25rem;
  border-radius: 4;
`;

export { StyledMain, StyledParagraph, StyledCode };
